import { t } from "i18next";
import * as ptBR from "./pt_BR.json";

export { t as i18n, ptBR };

interface AdmissionOriginDictionary {
  [key: number]: string;
  label: string;
}

interface EnumAdmissionDictionary {
  [key: number]: string;
}

export const context = {
  male: "male",
  female: "female",
};

export const dictionary = {
  action: {
    cancel: "action.cancel",
    edit: "action.edit",
    delete: "action.delete",
    register_new: "action.register_new",
    create_item: "action.create_item",
    update_save: "action.update_save",
    send: "action.update_send",
    remove: "action.remove",
    add_document: "action.add_document",
    addItem: "action.addItem",
    close_visualize: "action.close_visualize",
    view: "action.view",
    attach: "action.attach",
    yes: "action.yes",
    no: "action.no",
    exclude: "action.exclude",
    selectItem: "action.selectItem",
    contract_plan: "action.contract_plan",
    change_plan: "action.change_plan",
    call_commercial: "action.call_commercial",
    add_dependent: "action.add_dependent",
    conclude: "action.conclude",
    update_dependent: "action.update_dependent",
    seeItem: "action.see_item",
    resend: "action.resend",
    clear: "action.clear",
  },
  label: {
    yes: "label.yes",
    no: "label.no",
    list_title: "label.list_title",
    company_name: "label.company_name",
    trading_name: "label.trading_name",
    cnpj: "label.cnpj",
    action: "label.action",
    employer_data: "label.employer_data",
    employer_logo_image_placeholder: "label.employer_logo_image_placeholder",
    owner_name: "label.owner_name",
    owner_cpf: "label.owner_cpf",
    departments: "label.departments",
    send_to_esocial: "label.send_to_esocial",
    benefit: "label.benefit",
    address: "label.address",
    e_social_integration: "label.e_social_integration",
    digital_certificate_a1: "label.digital_certificate_a1",
    address_zip_code: "label.address_zip_code",
    address_street: "label.address_street",
    address_number: "label.address_number",
    address_complement: "label.address_complement",
    address_district: "label.address_district",
    address_city: "label.address_city",
    address_state: "label.address_state",
    new_item: "label.new_item",
    edit_item: "label.edit_item",
    documentation_list_title: "label.documentation_list_title",
    description: "label.description",
    admissional_system: "label.admissional_system",
    dashboard: "label.dashboard",
    registration: "label.registration",
    enrollmentEducation: "label.enrollment_education",
    internshipCommitmentTerm: "label.internship_commitment_term",
    documentation: "label.documentation",
    report: "label.report",
    finance: "label.finance",
    news: "label.news",
    admissionFlow: "label.admissionFlow",
    registrationForm: "label.registrationForm",
    history: "label.history",
    documentation_settings: "label.documentation_settings",
    itemSettings: "label.itemSettings",
    relationship_description: "label.relationship_description",
    itemType: "label.itemType",
    document_list: "label.document_list",
    name: "label.name",
    requested: "label.requested",
    required: "label.required",
    attachment: "label.attachment",
    due_date: "label.due_date",
    days: "label.days",
    weekly: "label.weekly",
    logs: "label.logs",
    author: "label.author",
    history_updates: "label.history_updates",
    date_time: "label.date_time",
    personal_data: "label.personal_data",
    admission: "label.admission",
    document: "label.document",
    notes: "label.notes",
    registered_documents: "label.registered_documents",
    rg: "label.rg",
    cpf: "label.cpf",
    pis_pasep: "label.pis_pasep",
    work_card_number: "label.work_card_number",
    work_card_serie: "label.work_card_serie",
    work_card_state: "label.work_card_state",
    voter_registration_number: "label.voter_registration_number",
    voter_registration_section: "label.voter_registration_section",
    voter_registration_zone: "label.voter_registration_zone",
    driver_license: "label.driver_license",
    driver_license_expiration_date: "label.driver_license_expiration_date",
    reservist_number: "label.reservist_number",
    reservist_serie: "label.reservist_serie",
    employee_address: "label.employee_address",
    work_contract: "label.work_contract",
    download_contract: "label.download_contract",
    error_download_contract: "label.error_download_contract",
    dependents: "label.dependents",
    registry: "label.registry",
    salary: "label.salary",
    experience_period: "label.experience_period",
    experience_period_extension: "label.experience_period_extension",
    job_occupation_register: "label.job_occupation_register",
    occupation: "label.occupation",
    admission_date: "label.admission_date",
    fgts_date: "label.fgts_date",
    is_temporary_collaborator: "label.is_temporary_collaborator",
    is_position_job_cumulative: "label.is_position_job_cumulative",
    data_on_temporary_work: "label.data_on_temporary_work",
    cnpj_cpf_temporary_employee: "label.cnpj_cpf_temporary_employee",
    employee_category_code: "label.employee_category_code",
    type_employment_contract: "label.type_employment_contract",
    assurance_clause: "label.assurance_clause",
    determining_object: "label.determining_object",
    worker_category: "label.worker_category",
    worker_establishment: "label.worker_establishment",
    registration_number: "label.registration_number",
    type_of_working_day: "label.type_of_working_day",
    is_part_time_contract: "label.is_part_time_contract",
    weekly_shift_nighttime: "label.weekly_shift_nighttime",
    description_contractual_weekly_shift: "label.description_contractual_weekly_shift",
    workload: "label.workload",
    contractual_weekly_journey: "label.contractual_weekly_journey",
    hours_per_week: "label.hours_per_week",
    hours_per_month: "label.hours_per_month",
    department: "label.department",
    admission_flow: "label.admission_flow",
    admission_flow_tooltip: "label.admission_flow_tooltip",
    step: "label.step",
    complete_step: "label.complete_step",
    complete_step_warning: "label.complete_step_warning",
    manager: "label.manager",
    gender: "label.gender",
    sex: "label.sex",
    social_name: "label.social_name",
    marital_status: "label.marital_status",
    education_level: "label.education_level",
    country_of_birth: "label.country_of_birth",
    country_of_nationality: "label.country_of_nationality",
    rehabilitated_or_readapted: "label.rehabilitated_or_readapted",
    infoCota: "label.infoCota",
    count_disability_people: "label.count_disability_people",
    email: "label.email",
    contact_phone_number: "label.contact_phone_number",
    this_number_is_whatsapp: "label.this_number_is_whatsapp",
    dependent_is_incapable_of_working: "label.dependent_is_incapable_of_working",
    dependent_income_tax: "label.dependent_income_tax",
    dependent_family_allowance: "label.dependent_family_allowance",
    birth_date: "label.birth_date",
    race: "label.race",
    has_any_disability: "label.has_any_disability",
    has_address_abroad: "label.has_address_abroad",
    disability: "label.disability",
    register_data: "label.register_data",
    full_name: "label.full_name",
    employee_documents: "label.employee_documents",
    request_documents_to_employee: "label.request_documents_to_employee",
    add_documents_manually: "label.add_documents_manually",
    search: "label.search",
    search_employee_or_employer_placeholder: "label.search_employee_or_employer_placeholder",
    admissional_data: "label.admissional_data",
    type: "label.type",
    reservist: "label.reservist",
    testEmployeeAdmission: "label.testEmployeeAdmission",
    personalEmail: "label.personalEmail",
    firstEmployment: "label.firstEmployment",
    relationType: "label.relationType",
    showInactives: "label.showInactives",
    type_of_employment_regime: "label.type_of_employment_regime",
    type_of_pension_scheme: "label.type_of_pension_scheme",
    payment_unit_remuneration: "label.payment_unit_remuneration",
    salary_description: "label.salary_description",
    type_of_event_ESocial: "label.type_of_event_ESocial",
    admission_type: "label.admission_type",
    admission_indicator: "label.admission_indicator",
    journey_regime: "label.journey_regime",
    activity_nature: "label.activity_nature",
    month_professional_category: "label.month_professional_category",
    cnpj_union_representative: "label.cnpj_union_representative",
    type_of_journey: "label.type_of_journey",
    number_of_hours: "label.number_of_hours",
    input: "label.input",
    output: "label.output",
    dependent: "label.dependent",
    weekdays: {
      monday: "label.weekdays.monday",
      tuesday: "label.weekdays.tuesday",
      wednesday: "label.weekdays.wednesday",
      thursday: "label.weekdays.thursday",
      friday: "label.weekdays.friday",
      saturday: "label.weekdays.saturday",
      sunday: "label.weekdays.sunday",
    },
    confirmation_required: "label.confirmation_required",
    newFeatureText: "label.newFeatureText",
  },
  prompt: {
    action_cant_be_undone: "prompt.action_cant_be_undone",
    delete_confirm: "prompt.delete_confirm",
    deleted_success: "prompt.deleted_success",
    created_success: "prompt.created_success",
    updated_success: "prompt.updated_success",
  },
  entity: {
    employer: "entity.employer",
    employment_relationship: "entity.employment_relationship",
    employment_relationships: "entity.employment_relationships",
    relationship: "entity.relationship",
    relationships: "entity.relationships",
    employee: "entity.employee",
    contract: "entity.contract",
    user: "entity.user",
    shift: "entity.shift",
    admission_flow: "entity.admission_flow",
  },
  pending_admission: {
    waiting: "pending_admission.waiting",
    conference: "pending_admission.conference",
  },
  admission: {
    word: "admission.word",
    dateStatus: {
      upToDate: "admission.dateStatus.upToDate",
      late: "admission.dateStatus.late",
      completed: "admission.dateStatus.completed",
    },
  },
  employee: {
    active: "employee.active",
    disabled: "employee.disabled",
  },
  employee_vacation: {
    upcoming: "employee_vacation.upcoming",
    expired: "employee_vacation.expired",
    approved: "employee_vacation.approved",
    for_approval: "employee_vacation.for_approval",
  },
  see_all: "see_all",
  see_details: "see_details",
  reminder: "reminder",
  accountants: "accountants",
  documentation_description: "documentation_description",
  new_admission_description: "new_admission_description",
  accountants_description: "accountants_description",
  step: "step",
  expiredSession: {
    title: "expiredSession.title",
    content: "expiredSession.content",
  },
  copyright: "copyright",
  support: "support",
  exit: "exit",
  return: "return",
  hello: "hello",
  do_search: "do_search",
  insert_new_item: "insert_new_item",
  insert: "insert",
  version: "version",
  bugOrFeatReport: "bugOrFeatReport",
  monthYearDateFormat: "monthYearDateFormat",
  monthYearDateLuxonFormat: "monthYearDateLuxonFormat",
  dateFormat: "dateFormat",
  fullDateFormat: "fullDateFormat",
  timeFormat: "timeFormat",
  longDateFormat: "longDateFormat",
  enterContractText: "enterContractText",
  homePage: "homePage",
  communication: "communication",
  integration: "Integração",
  variable: "variable",
  field: "field",
  field_other: "field_other",
  alright: "alright",
  send: "send",
  sending: "sending",
  ops: "ops",
  contractTextField: {
    label: "contractTextField.label.",
    value: "contractTextField.value.",
  },
  emailBodyTextField: {
    label: "emailBodyTextField.label.",
    value: "emailBodyTextField.value.",
  },
  emptyNotification: "emptyNotification",
  message: {
    requiredDocumentInformation: "message.requiredDocumentInformation",
    requiredField: "message.requiredField",
    requiredAttachment: "message.requiredAttachment",
    requiredRule: "message.requiredRule",
    emailRule: "message.emailRule",
    numberRule: "message.numberRule",
    numberRangeRule: "message.numberRangeRule",
    nonEmptyValue: "message.nonEmptyValue",
    tryAgain: {
      text: "message.tryAgain.text",
      alternativeAction: "message.tryAgain.alternativeAction",
    },
    verify: {
      employeeRegistration: "message.verify.employeeRegistration",
    },
    cannotFilter: "message.cannotFilter",
    voucherNotFound: "message.voucherNotFound",
    voucherAlreadyValidate: "message.voucherAlreadyValidate",
    periodNotAllowed: {
      title: "message.periodNotAllowed.title",
      sixMonths: "message.periodNotAllowed.sixMonths",
    },
    send_to_esocial: "message.send_to_esocial",
    rehabilitated_or_readapted: "message.rehabilitated_or_readapted",
    salary_description: "message.salary_description",
    type_of_event_ESocial: "message.type_of_event_ESocial",
    digital_certificate_a1: "message.digital_certificate_a1",
    registration_number: "message.registration_number",
    worker_establishment: "message.worker_establishment",
    job_occupation_register: "message.job_occupation_register",
    cnp_rule: "message.cnp_rule",
  },
  requiredAdmissionDocuments: "requiredAdmissionDocuments",
  error: {
    CREATE_ADMISSION_ERROR: {
      title: "error.CREATE_ADMISSION_ERROR.title",
      message: "error.CREATE_ADMISSION_ERROR.message",
    },
    ADMISSION_DOCUMENTS_REQUEST_EMAIL_ERROR: {
      title: "error.ADMISSION_DOCUMENTS_REQUEST_EMAIL_ERROR.title",
      message: "error.ADMISSION_DOCUMENTS_REQUEST_EMAIL_ERROR.message",
    },
    FINISH_ADMISSION_ERROR: {
      title: "error.FINISH_ADMISSION_ERROR.title",
      message: "error.FINISH_ADMISSION_ERROR.message",
    },
    SAVE_CONFIG_EMPLOYMENT_RELATIONSHIP_DOCUMENT_ERROR: {
      title: "error.SAVE_CONFIG_EMPLOYMENT_RELATIONSHIP_DOCUMENT_ERROR.title",
      message: "error.SAVE_CONFIG_EMPLOYMENT_RELATIONSHIP_DOCUMENT_ERROR.message",
    },
    AUTH_LOGIN_ERROR: {
      title: "error.AUTH_LOGIN_ERROR.title",
      message: "error.AUTH_LOGIN_ERROR.message",
    },
    GET_EMPLOYMENT_RELATIONSHIP_DOCUMENT_ERROR: {
      title: "error.GET_EMPLOYMENT_RELATIONSHIP_DOCUMENT_ERROR.title",
      message: "error.GET_EMPLOYMENT_RELATIONSHIP_DOCUMENT_ERROR.message",
    },
    GET_EMPLOYER_ERROR: {
      title: "error.GET_EMPLOYER_ERROR.title",
      message: "error.GET_EMPLOYER_ERROR.message",
    },
    GET_DEPARTMENT_ERROR: {
      title: "error.GET_DEPARTMENT_ERROR.title",
      message: "error.GET_DEPARTMENT_ERROR.message",
    },
    GET_PENDING_ADMISSION_ERROR: {
      title: "error.GET_PENDING_ADMISSION_ERROR.title",
      message: "error.GET_PENDING_ADMISSION_ERROR.message",
    },
    GET_EMPLOYEE_ERROR: {
      title: "error.GET_EMPLOYEE_ERROR.title",
      message: "error.GET_EMPLOYEE_ERROR.message",
    },
    AMOUNT_MONTH_ADMISSION_ERROR: {
      title: "error.AMOUNT_MONTH_ADMISSION_ERROR.title",
      message: "error.AMOUNT_MONTH_ADMISSION_ERROR.message",
    },
    AMOUNT_DATE_STATUS_ADMISSION_ERROR: {
      title: "error.AMOUNT_DATE_STATUS_ADMISSION_ERROR.title",
      message: "error.AMOUNT_DATE_STATUS_ADMISSION_ERROR.message",
    },
    GET_REMINDER_ERROR: {
      title: "error.GET_REMINDER_ERROR.title",
      message: "error.GET_REMINDER_ERROR.message",
    },
    GET_VACATION_ERROR: {
      title: "error.GET_VACATION_ERROR.title",
      message: "error.GET_VACATION_ERROR.message",
    },
    GET_CONTRACT_ERROR: {
      title: "error.GET_CONTRACT_ERROR.title",
      message: "error.GET_CONTRACT_ERROR.message",
    },
    GET_EMPLOYMENT_RELATIONSHIP_ERROR: {
      title: "error.GET_EMPLOYMENT_RELATIONSHIP_ERROR.title",
      message: "error.GET_EMPLOYMENT_RELATIONSHIP_ERROR.message",
    },
    UPDATE_CONTRACT_ERROR: {
      title: "error.UPDATE_CONTRACT_ERROR.title",
      message: "error.UPDATE_CONTRACT_ERROR.message",
    },
    CREATE_CONTRACT_ERROR: {
      title: "error.CREATE_CONTRACT_ERROR.title",
      message: "error.CREATE_CONTRACT_ERROR.message",
    },
    DELETE_CONTRACT_ERROR: {
      title: "error.DELETE_CONTRACT_ERROR.title",
      message: "error.DELETE_CONTRACT_ERROR.message",
    },
    GET_CHANGE_HISTORY_ERROR: {
      title: "error.GET_CHANGE_HISTORY_ERROR.title",
      message: "error.GET_CHANGE_HISTORY_ERROR.message",
    },
    GET_MANAGER_USER_ERROR: {
      title: "error.GET_MANAGER_USER_ERROR.title",
      message: "error.GET_MANAGER_USER_ERROR.message",
    },
    CREATE_EMPLOYEE_ERROR: {
      title: "error.CREATE_EMPLOYEE_ERROR.title",
      message: "error.CREATE_EMPLOYEE_ERROR.message",
    },
    GET_BENEFIT_ERROR: {
      title: "error.GET_BENEFIT_ERROR.title",
      message: "error.GET_BENEFIT_ERROR.message",
    },
    GET_ADMISSION_FLOW_ERROR: {
      title: "error.GET_ADMISSION_FLOW_ERROR.title",
      message: "error.GET_ADMISSION_FLOW_ERROR.message",
    },
    CREATE_REMINDER_ERROR: {
      title: "error.CREATE_REMINDER_ERROR.title",
      message: "error.CREATE_REMINDER_ERROR.message",
    },
    DELETE_REMINDER_ERROR: {
      title: "error.DELETE_REMINDER_ERROR.title",
      message: "error.DELETE_REMINDER_ERROR.message",
    },
    UPDATE_REMINDER_ERROR: {
      title: "error.UPDATE_REMINDER_ERROR.title",
      message: "error.UPDATE_REMINDER_ERROR.message",
    },
    GET_EMPLOYEE_NOTE_ERROR: {
      title: "error.GET_EMPLOYEE_NOTE_ERROR.title",
      message: "error.GET_EMPLOYEE_NOTE_ERROR.message",
    },
    GET_REQUIRE_DOCUMENT: {
      title: "error.GET_REQUIRE_DOCUMENT.title",
      message: "error.GET_REQUIRE_DOCUMENT.message",
    },
    UPDATE_EMPLOYEE_ERROR: {
      title: "error.UPDATE_EMPLOYEE_ERROR.title",
      message: "error.UPDATE_EMPLOYEE_ERROR.message",
    },
    ATTACH_DOCUMENT_ERROR: {
      title: "error.ATTACH_DOCUMENT_ERROR.title",
      message: "error.ATTACH_DOCUMENT_ERROR.message",
    },
    ATTACH_IMAGE_ERROR: {
      title: "error.ATTACH_IMAGE_ERROR.title",
      message: "error.ATTACH_IMAGE_ERROR.message",
    },
    ATTACH_DOCUMENT_ERROR_IMAGE: {
      title: "error.ATTACH_DOCUMENT_ERROR_IMAGE.title",
      message: "error.ATTACH_DOCUMENT_ERROR_IMAGE.message",
    },
    ATTACH_DOCUMENT_ERROR_SIZE: {
      title: "error.ATTACH_DOCUMENT_ERROR_SIZE.title",
      message: "error.ATTACH_DOCUMENT_ERROR_SIZE.message",
    },
    DIGITAL_CERTIFICATE_ERROR: {
      message: "error.DIGITAL_CERTIFICATE_ERROR.message",
    },
    DIGITAL_CERTIFICATE_ERROR_SIZE: {
      title: "error.DIGITAL_CERTIFICATE_ERROR_SIZE.title",
      message: "error.DIGITAL_CERTIFICATE_ERROR_SIZE.message",
    },
    SEND_DOCUMENT_ERROR: {
      title: "error.SEND_DOCUMENT_ERROR.title",
      message: "error.SEND_DOCUMENT_ERROR.message",
    },
    CREATE_EMPLOYER_ERROR: {
      title: "error.CREATE_EMPLOYER_ERROR.title",
      message: "error.CREATE_EMPLOYER_ERROR.message",
    },
    UPDATE_EMPLOYER_ERROR: {
      title: "error.UPDATE_EMPLOYER_ERROR.title",
      message: "error.UPDATE_EMPLOYER_ERROR.message",
    },
    GET_USER_ERROR: {
      title: "error.GET_USER_ERROR.title",
      message: "error.GET_USER_ERROR.message",
    },
    UPDATE_USER_ERROR: {
      title: "error.UPDATE_USER_ERROR.title",
      message: "error.UPDATE_USER_ERROR.message",
    },
    UPDATE_EMPLOYMENT_RELATIONSHIP_ERROR: {
      title: "error.UPDATE_EMPLOYMENT_RELATIONSHIP_ERROR.title",
      message: "error.UPDATE_EMPLOYMENT_RELATIONSHIP_ERROR.message",
    },
    CREATE_EMPLOYMENT_RELATIONSHIP_ERROR: {
      title: "error.CREATE_EMPLOYMENT_RELATIONSHIP_ERROR.title",
      message: "error.CREATE_EMPLOYMENT_RELATIONSHIP_ERROR.message",
    },
    LOGIN_ERROR: {
      title: "error.LOGIN_ERROR.title",
      message: "error.LOGIN_ERROR.message",
    },
    API_ERROR: {
      title: "error.API_ERROR.title",
      message: "error.API_ERROR.message",
    },
    INFO_ERROR: {
      title: "error.INFO_ERROR.title",
      message: "error.INFO_ERROR.message",
    },
    GET_DOCUMENTS_ERROR: {
      title: "error.GET_DOCUMENTS_ERROR.title",
      message: "error.GET_DOCUMENTS_ERROR.message",
    },
    DELETE_EMPLOYMENT_RELATIONSHIP_ERROR: {
      title: "error.DELETE_EMPLOYMENT_RELATIONSHIP_ERROR.title",
      message: "error.DELETE_EMPLOYMENT_RELATIONSHIP_ERROR.message",
    },
    DELETE_EMPLOYEE_ERROR: {
      title: "error.DELETE_EMPLOYEE_ERROR.title",
      message: "error.DELETE_EMPLOYEE_ERROR.message",
    },
    ACTIVE_EMPLOYEE_ERROR: {
      title: "error.ACTIVE_EMPLOYEE_ERROR.title",
      message: "error.ACTIVE_EMPLOYEE_ERROR.message",
    },
    DISABLED_EMPLOYEE_ERROR: {
      title: "error.DISABLED_EMPLOYEE_ERROR.title",
      message: "error.DISABLED_EMPLOYEE_ERROR.message",
    },
    DELETE_EMPLOYER_ERROR: {
      title: "error.DELETE_EMPLOYER_ERROR.title",
      message: "error.DELETE_EMPLOYER_ERROR.message",
    },
    CREATE_DEPARTMENT_ERROR: {
      title: "error.CREATE_DEPARTMENT_ERROR.title",
      message: "error.CREATE_DEPARTMENT_ERROR.message",
    },
    CREATE_BENEFIT_ERROR: {
      title: "error.CREATE_BENEFIT_ERROR.title",
      message: "error.CREATE_BENEFIT_ERROR.message",
    },
    CREATE_EMPLOYEE_SENDING_CONFIGURATION_ERROR: {
      title: "error.CREATE_EMPLOYEE_SENDING_CONFIGURATION_ERROR.title",
      message: "error.CREATE_EMPLOYEE_SENDING_CONFIGURATION_ERROR.message",
    },
    GET_EMPLOYEE_SENDING_CONFIGURATION_ERROR: {
      title: "error.GET_EMPLOYEE_SENDING_CONFIGURATION_ERROR.title",
      message: "error.GET_EMPLOYEE_SENDING_CONFIGURATION_ERROR.message",
    },
    UPDATE_EMPLOYEE_SENDING_CONFIGURATION_ERROR: {
      title: "error.UPDATE_EMPLOYEE_SENDING_CONFIGURATION_ERROR.title",
      message: "error.UPDATE_EMPLOYEE_SENDING_CONFIGURATION_ERROR.message",
    },
    ADMISSION_LIMIT_ERROR: {
      title1: "error.ADMISSION_LIMIT_ERROR.title1",
      title2: "error.ADMISSION_LIMIT_ERROR.title2",
      title3: "error.ADMISSION_LIMIT_ERROR.title3",
      message1: "error.ADMISSION_LIMIT_ERROR.message1",
      message2_1: "error.ADMISSION_LIMIT_ERROR.message2_1",
      message2_2: "error.ADMISSION_LIMIT_ERROR.message2_2",
      message3_1: "error.ADMISSION_LIMIT_ERROR.message3_1",
      message3_2: "error.ADMISSION_LIMIT_ERROR.message3_2",
    },
    E_SOCIAL_INTEGRATION_ERROR: {
      title: "error.E_SOCIAL_INTEGRATION_ERROR.title",
      message: "error.E_SOCIAL_INTEGRATION_ERROR.message",
    },
    E_CERTIFICATE_PASSWORD_ERROR: {
      message: "error.E_CERTIFICATE_PASSWORD_ERROR.message",
    },
    E_SOCIAL_SEND_BATCH_UNAVAILABLE_SERVICE_ERROR: {
      message: "error.E_SOCIAL_SEND_BATCH_UNAVAILABLE_SERVICE_ERROR.message",
    },
    E_SOCIAL_SEND_BATCH_UNHANDLED_ERROR: {
      message: "error.E_SOCIAL_SEND_BATCH_UNHANDLED_ERROR.message",
    },
    E_SOCIAL_BATCH_STATUS_UNAVAILABLE_SERVICE_ERROR: {
      message: "error.E_SOCIAL_BATCH_STATUS_UNAVAILABLE_SERVICE_ERROR.message",
    },
    E_SOCIAL_BATCH_STATUS_UNHANDLED_ERROR: {
      message: "error.E_SOCIAL_BATCH_STATUS_UNHANDLED_ERROR.message",
    },
    GET_E_SOCIAL_DEPENDENT_ERROR: {
      title: "error.GET_E_SOCIAL_DEPENDENT_ERROR.title",
      message: "error.GET_E_SOCIAL_DEPENDENT_ERROR.message",
    },
    CREATE_E_SOCIAL_DEPENDENT_ERROR: {
      title: "error.CREATE_E_SOCIAL_DEPENDENT_ERROR.title",
      message: "error.CREATE_E_SOCIAL_DEPENDENT_ERROR.message",
    },
    UPDATE_E_SOCIAL_DEPENDENT_ERROR: {
      title: "error.UPDATE_E_SOCIAL_DEPENDENT_ERROR.title",
      message: "error.UPDATE_E_SOCIAL_DEPENDENT_ERROR.message",
    },
    DELETE_E_SOCIAL_DEPENDENT_ERROR: {
      title: "error.DELETE_E_SOCIAL_DEPENDENT_ERROR.title",
      message: "error.DELETE_E_SOCIAL_DEPENDENT_ERROR.message",
    },
    start: "error.",
    title: ".title",
    message: ".message",
    frontend: {
      downloadReceipt: "error.frontend.download_receipt",
    },
    ADMISSION_FLOW_EXECUTE_STEP_ERROR: {
      message: "error.ADMISSION_FLOW_EXECUTE_STEP_ERROR.message",
      description: "error.ADMISSION_FLOW_EXECUTE_STEP_ERROR.description",
    },
    ADMISSION_FLOW_DELETE_ERROR: {
      message: "error.ADMISSION_FLOW_DELETE_ERROR.message",
      description: "error.ADMISSION_FLOW_DELETE_ERROR.description",
    },
  },
  info: {
    FINISH_ADMISSION_INFO: {
      title: "info.FINISH_ADMISSION_INFO.title",
      message: "info.FINISH_ADMISSION_INFO.message",
      specificMessage: "info.FINISH_ADMISSION_INFO.specific_message",
      requiredDocument: {
        0: "label.rg",
        1: "label.cpf",
        2: "label.reservist_number",
        3: "label.employee_address",
        4: "label.enrollment_education",
        5: "label.internship_commitment_term",
        6: "label.pis_pasep",
        7: "label.work_card_number",
        8: "label.voter_registration_number",
        9: "label.driver_license",
        10: "label.gender",
      } as EnumAdmissionDictionary,
    },
    E_SOCIAL_WARNING: {
      title: "info.E_SOCIAL_WARNING.title",
      message: "info.E_SOCIAL_WARNING.message",
    },
    E_SOCIAL_ALMOST_THERE: {
      title: "info.E_SOCIAL_ALMOST_THERE.title",
      message: "info.E_SOCIAL_ALMOST_THERE.message",
    },
  },
  success: {
    ADMISSION_DOCUMENTS_REQUEST_EMAIL_SUCCESS: {
      title: "success.ADMISSION_DOCUMENTS_REQUEST_EMAIL_SUCCESS.title",
      message: "success.ADMISSION_DOCUMENTS_REQUEST_EMAIL_SUCCESS.message",
    },
    FINISH_ADMISSION_SUCCESS: {
      title: "success.FINISH_ADMISSION_SUCCESS.title",
      message: "success.FINISH_ADMISSION_SUCCESS.message",
    },
    ATTACH_DOCUMENT_SUCCESS: {
      title: "success.ATTACH_DOCUMENT_SUCCESS.title",
      message: "success.ATTACH_DOCUMENT_SUCCESS.message",
    },
    IMPORT_CERTIFICATE_SUCCESS: {
      message: "success.IMPORT_CERTIFICATE_SUCCESS.message",
    },
    ADMISSION_FLOW_EXECUTE_STEP_SUCCESS: {
      message: "success.ADMISSION_FLOW_EXECUTE_STEP_SUCCESS.message",
      description: "success.ADMISSION_FLOW_EXECUTE_STEP_SUCCESS.description",
    },
    ADMISSION_FLOW_DELETE_SUCCESS: {
      message: "success.ADMISSION_FLOW_DELETE_SUCCESS.message",
      description: "success.ADMISSION_FLOW_DELETE_SUCCESS.description",
    },
  },
  confirm: {
    FINISH_ADMISSION_CONFIRM: {
      title: "confirm.FINISH_ADMISSION_CONFIRM.title",
      message: "confirm.FINISH_ADMISSION_CONFIRM.message",
    },
  },
  saveAndNextStep: "saveAndNextStep",
  saveAndSendEmail: "saveAndSendEmail",
  tryAgain: "tryAgain",
  unknown: "unknown",
  components: {
    perfil: {
      modalTitle: "Ops...",
      modalEmail: "Os valores de alguns campos não estão preenchidos corretamente!",
      modalSenha: "As senhas informadas precisam ser idênticas",
      messageAtualizado: "Dados atualizados com sucecsso!",
      messageEmail: "O e-mail informado já possui cadastro no sistema!",
      messageErro: "Erro ao atualizar seus dados",
      formItems: {
        masterTitle: "Dados de acesso",
        managerTitle: "Meus dados",
        masterName: "Nome da empresa",
        nome: "Nome de exibição",
        email: "E-mail de acesso",
        razaoSocial: "Razão social",
        senha: "Nova senha",
        altere: "Altere esse campo apenas se desejar alterar a sua senha",
        confirme: "Confirme a nova senha",
        senhas: "As senhas devem ser idênticas",
        foto: {
          master: "Foto de perfil",
          manager: "Foto do gerente",
          employee: "Foto do colaborador",
        },
        manter: "Manter esta imagem?",
        avatar: "Avatar",
      },
      updatePassword: "Alterar minha senha",
      updatePasswordModal: {
        title: "Alteração de senha",
        save: "Salvar",
        currentPassword: "Senha atual",
        newPassword: "Nova senha",
        newPasswordConfirmation: "Confirme a nova senha",
        warnings: {
          equalPasswords: "As senhas não correspondem!",
          smallPasswords: "A nova senha deve ter no mínimo 8 caracteres!",
        },
        notifications: {
          passwordUpdated: "Senha atualizada com sucesso!",
          incorrectCurrentPassword: "A senha atual informada está incorreta!",
        },
      },
      alterar: "Alterar foto",
      contactInfo: {
        title: "Dados para contato",
        name: "Nome do gestor",
        phone: "Telefone",
        addPhone: "Adicionar outro telefone",
      },
    },
    sendings: {
      title: "components.sendings.title",
      titleModalRegister: "components.sendings.titleModalRegister",
      titleModalEdit: "components.sendings.titleModalEdit",
      formItems: {
        defaultTitle: "components.sendings.formItems.defaultTitle",
        titleForm: "components.sendings.formItems.titleForm",
        title: "components.sendings.formItems.title",
        titlePlaceHolder: "components.sendings.formItems.titlePlaceHolder",
        emailSubject: "components.sendings.formItems.emailSubject",
        emailSubjectPlaceHolder: "components.sendings.formItems.emailSubjectPlaceHolder",
        emailBody: "components.sendings.formItems.emailBody",
        emailBodyPlaceHolder: "components.sendings.formItems.emailBodyPlaceHolder",
        fraseTitle: "components.sendings.formItems.fraseTitle",
        frasePlaceHolder: "components.sendings.formItems.frasePlaceHolder",
        descriptionPlaceholder: "components.sendings.formItems.descriptionPlaceholder",
        logo: "components.sendings.formItems.logo",
        principalColor: "components.sendings.formItems.principalColor",
        secondaryColor: "components.sendings.formItems.secondaryColor",
      },
    },
    settings: {
      messages: {
        success: "Atualizado com sucesso",
        error: "Erro ao tentar atualizar suas configurações",
        copiado: "Código copiado para a área de tranferência",
      },
      notificacoesComputador: "Receber notificações no computador (navegador)",
      notificacoesApp: "Receber notificações no app de gestão",
      notificacoesEmail: "Receber notificações por e-mail",
      notificacoesSolicitacoes: "Receber notificações de envio da documentação",
      token: "Token de autenticação da API",
      identificacao: "Identificação da API é gerada pelo sistema. Para alterá-la, contate o suporte.",
      selecioneAlguemNotificacao: "Selecione abaixo quem você gostaria de receber notificação ao registrar ponto",
      colaboradores: "Colaboradores",
      receberNotificacao: "Receber notificação",
    },
    finance: {
      financeAccessDenied: {
        title: "components.finance.financeAccessDenied.title",
        subtitle: "components.finance.financeAccessDenied.subtitle",
      },
    },
    accessBlockByAccounting: {
      title: "components.accessBlockByAccounting.title",
      texts: {
        p1: "components.accessBlockByAccounting.texts.1",
        p2: "components.accessBlockByAccounting.texts.2",
        p3: "components.accessBlockByAccounting.texts.3",
        p4: "components.accessBlockByAccounting.texts.4",
        p5: "components.accessBlockByAccounting.texts.5",
      },
    },
    employeeLimitReachedModal: {
      title: "components.employeeLimitReachedModal.title",
      text: "components.employeeLimitReachedModal.text",
      button: "components.employeeLimitReachedModal.button",
      notifications: {
        success: {
          title: "components.employeeLimitReachedModal.notifications.success.title",
          texts: {
            p1: "components.employeeLimitReachedModal.notifications.success.texts.1",
            p2: "components.employeeLimitReachedModal.notifications.success.texts.2",
            p3: "components.employeeLimitReachedModal.notifications.success.texts.3",
          },
        },
        error: "components.employeeLimitReachedModal.notifications.error",
      },
    },
    textFilterColumn: {
      search: "components.textFilterColumn.search",
      reset: "components.textFilterColumn.reset",
    },
    systemSelector: {
      admission: "components.systemSelector.admission",
      timesheet: "components.systemSelector.timesheet",
      vacation: "components.systemSelector.vacation",
    },
  },
  image_updated: "Imagem alterada com sucesso!",
  image_update_error: "Erro ao alterar imagem!",
  locale: "pt-br",
  item: {
    receipt: "item.receipt",
  },
  pages: {
    employee: {
      table: {
        columns: {
          name: "pages.employee.table.columns.name",
          admission_date: "pages.employee.table.columns.admission_date",
          employer: "pages.employee.table.columns.employer",
          admission_origin: {
            label: "pages.employee.table.columns.admission_origin.label",
            1: "pages.employee.table.columns.admission_origin.1",
            2: "pages.employee.table.columns.admission_origin.2",
          } as AdmissionOriginDictionary,
        },
      },
    },
    login: {
      fields: {
        signIn: "pages.login.fields.signIn",
        email: {
          placeholder: "pages.login.fields.email.placeholder",
        },
        password: {
          placeholder: "pages.login.fields.password.placeholder",
        },
        forgotPassword: "pages.login.fields.forgotPassword",
      },
    },
    forgotPassword: {
      title: "pages.forgotPassword.title",
      fields: {
        email: {
          label: "pages.forgotPassword.fields.email.label",
          placeholder: "pages.forgotPassword.fields.email.placeholder",
        },
        backToLoginPage: "pages.forgotPassword.fields.backToLoginPage",
      },
      notifications: {
        success: "pages.forgotPassword.notifications.success",
        user_not_found: "pages.forgotPassword.notifications.user_not_found",
        error_to_send_email: "pages.forgotPassword.notifications.error_to_send_email",
        generic: "pages.forgotPassword.notifications.generic",
        internal_server_error: "pages.forgotPassword.notifications.internal_server_error",
      },
    },
    resetPassword: {
      title: "pages.resetPassword.title",
      subtitle1: "pages.resetPassword.subtitle1",
      subtitle2: "pages.resetPassword.subtitle2",
      fields: {
        password: "pages.resetPassword.fields.password",
        passwordConfirmation: "pages.resetPassword.fields.passwordConfirmation",
        button: "pages.resetPassword.fields.button",
        loadingButton: "pages.resetPassword.fields.loadingButton",
      },
      notifications: {
        invalid_password: "pages.resetPassword.notifications.invalid_password",
        invalid_password_confirmation: "pages.resetPassword.notifications.invalid_password_confirmation",
        success: "pages.resetPassword.notifications.success",
        error: "pages.resetPassword.notifications.error",
      },
    },
    admissionFlow: {
      title: "pages.admissionFlow.title",
      addButton: "pages.admissionFlow.addButton",
      table: {
        columns: {
          name: "pages.admissionFlow.table.columns.name",
          createdAt: "pages.admissionFlow.table.columns.createdAt",
          actions: {
            label: "pages.admissionFlow.table.columns.actions.label",
            options: {
              edit: "pages.admissionFlow.table.columns.actions.options.edit",
              delete: "pages.admissionFlow.table.columns.actions.options.delete",
            },
          },
        },
      },
    },
    admissionFlowCreate: {
      tabs: {
        t1: {
          titles: {
            new: "pages.admissionFlowCreate.tabs.t1.titles.new",
            edit: "pages.admissionFlowCreate.tabs.t1.titles.edit",
          },
          cards: {
            newStep: "pages.admissionFlowCreate.tabs.t1.cards.newStep",
            settings: {
              title: "pages.admissionFlowCreate.tabs.t1.cards.settings.title",
              editButton: "pages.admissionFlowCreate.tabs.t1.cards.settings.editButton",
            },
            others: {
              titles: {
                t1: "pages.admissionFlowCreate.tabs.t1.cards.others.titles.t1",
                t2: "pages.admissionFlowCreate.tabs.t1.cards.others.titles.t2",
                t3: "pages.admissionFlowCreate.tabs.t1.cards.others.titles.t3",
              },
              content: "pages.admissionFlowCreate.tabs.t1.cards.others.content",
            },
          },
          step: {
            label: "pages.admissionFlowCreate.tabs.t1.step.label",
            fields: {
              subject: "pages.admissionFlowCreate.tabs.t1.step.fields.subject",
              text: "pages.admissionFlowCreate.tabs.t1.step.fields.text",
              phrase: {
                label: "pages.admissionFlowCreate.tabs.t1.step.fields.phrase.label",
                defaultValue: "pages.admissionFlowCreate.tabs.t1.step.fields.phrase.defaultValue",
              },
              description: {
                label: "pages.admissionFlowCreate.tabs.t1.step.fields.description.label",
                defaultValue: "pages.admissionFlowCreate.tabs.t1.step.fields.description.defaultValue",
              },
            },
          },
          buttons: {
            register: "pages.admissionFlowCreate.tabs.t1.buttons.register",
            edit: "pages.admissionFlowCreate.tabs.t1.buttons.edit",
            clear: "pages.admissionFlowCreate.tabs.t1.buttons.clear",
            cancel: "pages.admissionFlowCreate.tabs.t1.buttons.cancel",
          },
          notifications: {
            create: {
              success: "pages.admissionFlowCreate.tabs.t1.notifications.create.success",
              error: "pages.admissionFlowCreate.tabs.t1.notifications.create.error",
            },
            update: {
              success: "pages.admissionFlowCreate.tabs.t1.notifications.update.success",
              error: "pages.admissionFlowCreate.tabs.t1.notifications.update.error",
            },
          },
        },
      },
    },
    news: {
      loading: "pages.news.loading",
      latest: "pages.news.latest",
      empty: "pages.news.empty",
    },
  },
};
